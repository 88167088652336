import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            autoSavePending: false,
            userActionsStatus: {
                PROCESSING: 'PROCESSING',
                RESOLVED: 'RESOLVED'
            },
            userActionsTypes: {
                DOCUMENT_GENERATION_REQUEST: 'DOCUMENT_GENERATION_REQUEST',
                SIGN_DOCUMENT: 'SIGN_DOCUMENT',
                APPLICATION_REVIEW: 'APPLICATION_REVIEW'
            },
            disableDraftStatus: ['sign_pending', 'closed'],
            hidePreviewStatus: ['new', 'closed'],
            applicationCheckStatus: ['approved', 'rejected', 'rejected_in_progress'],
            rejectedStatus: ['rejected', 'rejected_in_progress'],
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            currentUser: "auth/currentUser",
            userActions: "applications/form/userActions",
        }),
        isDraftDisabled() {
            return this.disableDraftStatus.includes(this.record.internal_status)
                || !this.record.assigned_staff_user_id
                || this.record.assigned_staff_user_id !== this.currentUser.user_id;
        },
        generateDocumentActions() {
            return this.userActions.filter((el) => el.action_type === this.userActionsTypes.DOCUMENT_GENERATION_REQUEST);
        },
        isDocumentGenerated() {
            return !!this.generateDocumentActions.some((el) => el.status === this.userActionsStatus.RESOLVED);
        },
        isCurrentUserAssigned() {
            return this.record.assigned_staff_user_id === this.currentUser.user_id;
        },
        isHumanReview() {
            return !!this.userActions.find((el) => el.status === this.userActionsStatus.PROCESSING && el.action_type === 'HUMAN_REVIEW');
        }
    }
}
